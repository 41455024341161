.why-hire-me-cli {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ffffff;
}

.cli-subheader {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #ffffff;
}

.cli-container {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 65vw;
}

.cli-content {
  padding: 1rem;
  overflow-y: auto;
  max-height: 400px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 0.8rem;
}

.cli-content > div {
  margin-bottom: 0.5rem;
}

.cli-content .skill-title {
  margin-top: 1rem;
  text-align: center;
}

.cli-content .skill-description {
  font-family: monospace;
}

.cli-input-area {
  padding: 0.5rem 1rem;
  border-top: 0.5px solid #333;
  bottom: 0;
}

.cli-input {
  background-color: transparent;
  color: #00ff00; /* Bright lime green color */
  caret-color: #00ff00; /* Cursor color */
  font-family: "Courier New", Courier, monospace;
  font-size: 0.9em;
  width: 100%;
  border: none;
  outline: none;
}

.cli-input::placeholder {
  color: #00aa00; /* Slightly darker green for placeholder text */
}

.cli-cursor {
  color: #00ff00;
  font-weight: bold;
  animation: blink 0.7s infinite;
  position: relative;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .cli-subheader {
    font-size: 1rem;
    padding: 0 1rem;
  }
}
