/* Projects.css */

.project {
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 100px 0;
  border-bottom: 1px solid #ffffff;
  padding-top: 40px;
  padding-bottom: 40px;
}

.project h2 {
  text-align: center;
  color: #ffffff;
}

.project p {
  text-align: left;
  color: #cccccc;
}

.project .card {
  background-color: #2a2a2a;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.project .card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.project .card-title {
  color: #ff6384;
  font-size: 1.05rem;
}

.project .card-text {
  color: #cccccc;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.project .btn-primary {
  background-color: #36a2eb;
  border: none;
  transition: background-color 0.3s ease;
  margin-top: auto;
  align-self: flex-start;
}

.project .btn-primary:hover {
  background-color: #1e90ff;
}

@media (max-width: 768px) {
  .project {
    padding: 50px 0;
  }

  .project .card {
    margin-bottom: 2rem;
  }

  .project .card-title {
    font-size: 1.1rem;
  }

  .project .card-text {
    font-size: 0.9rem;
  }
}
