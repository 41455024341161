.hero {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the entire hero content */
}

.hero-background {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin-top: 70px;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

.hero-background-mobile {
  display: none;
}
.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgb(0, 0, 0) 0%,
    rgb(0, 0, 0) 33%,
    rgba(0, 0, 0, 0.33) 67%,
    rgba(0, 0, 0, 0) 100%
  );
}

.hero-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: -30%; /* Adjust this value to move boxes more to the left if needed */
  max-width: 750px;
  margin-top: 100px;
}

.hero-quote-box.shadow-box {
  border-radius: 0px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.827);
}

.hero-quote-box {
  border-radius: 0px;
  background-color: rgba(0, 0, 0, 0.748);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 190px;
  width: 65%;
  scale: 1.1;
}
.hero-quote {
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.6;
  color: #e1eca9;
  border-left: 4px solid #4bc0c0;
  padding-left: 15px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.hero-quote-author {
  font-size: 1rem;
  color: white;
  text-align: right;
  margin-bottom: 0;
  margin-right: 10px;
}

.hero-text-box {
  background-color: rgba(0, 0, 0, 0.468);
  padding: 20px;
  margin-left: 150px;
  max-height: 170px;
  width: 100%;
  margin-top: 150px;
}

.hero-intro {
  font-size: 0.8rem;
  line-height: 1.5;
  color: white;
  margin-bottom: 15px;
}

.hero-buttons {
  display: flex;
  gap: 50px;
  margin-top: 30px;
  margin-left: 250px;
}
@media (max-width: 1024px) {
  .hero-quote-box {
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .hero {
    height: auto;
    min-height: 100vh;
  }

  .hero-background {
    display: none;
  }

  .hero-background-mobile {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    scale: 0.75;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .hero-container {
    left: 0;
    align-items: center;
    margin-top: 50px;
  }

  .hero-quote-box {
    margin-left: 0;
    width: 90%;
    max-width: 90%;
    scale: 0.6;
  }

  .hero-text-box {
    margin-left: 0;
    width: 90%;
    max-width: 90%;
    margin-top: 50px;
  }

  .hero-buttons {
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-left: 0;
    gap: 20px;
  }

  .btn-custom {
    width: 100%;
    max-width: 200px;
  }
}
@media (max-width: 576px) {
  .hero-quote-box {
    padding: 1rem;
  }

  .hero-quote {
    font-size: 1rem;
  }

  .hero-quote-author {
    font-size: 0.9rem;
  }

  .hero-intro {
    font-size: 0.7rem;
  }
}
