/* FONT
================================================== */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

@font-face {
  font-family: "FontAwesome";
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/webfonts/fa-solid-900.woff2")
      format("woff2"),
    url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/webfonts/fa-solid-900.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
}

body {
  font-family: "Roboto", sans-serif;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "\f099";
} /* '' */
.icon-facebook:before {
  content: "\f09a";
} /* '' */
.icon-gplus:before {
  content: "\f0d5";
} /* '' */
.icon-linkedin:before {
  content: "\f0e1";
} /* '' */
.icon-linkedin:before {
  content: "\f0e1";
} /* '' */
.icon-up:before {
  content: "\f176";
} /* '' */

/* CUSTOM RESETS
    ================================================== */
a {
  transition: color 0.25s ease-in-out;
  -moz-transition: color 0.25s ease-in-out;
  -webkit-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  -ms-transition: color 0.25s ease-in-out;
  color: #ffffff; /* Changed to white */
}

a:focus,
a:hover {
  outline: 0 none;
  text-decoration: none;
  color: #1e90ff; /* Highlight color for dark theme */
}

h4 {
  font-size: 30px;
  color: #ffffff; /* Changed to white */
}

/* Buttons */
.btn {
  min-width: 145px;
  line-height: 50px;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0;
  transition: box-shadow 0.25s ease-in-out;
  -moz-transition: box-shadow 0.25s ease-in-out;
  -webkit-transition: box-shadow 0.25s ease-in-out;
  -o-transition: box-shadow 0.25s ease-in-out;
  -ms-transition: box-shadow 0.25s ease-in-out;
}

.btn-fill {
  color: #ffffff;
  background-color: #333333; /* Darker shade */
}

.btn-border {
  background-color: transparent;
  color: #ffffff; /* Changed to white */
  border: 1px solid #ffffff; /* Changed to white */
}

.btn-fill:hover,
.btn-fill:focus {
  color: #ffffff;
  background-color: #444444; /* Darken on hover */
}

.btn:hover,
.btn:focus {
  box-shadow: 0px 10px 18px 0px rgba(255, 255, 255, 0.15); /* Lighter shadow */
}

/* Input fields */
.form-control {
  padding-left: 10px;
  border: 0;
  border-bottom: 1px solid #ffffff; /* Changed to white */
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  outline: 0 none transparent;
  -webkit-appearance: none;
  color: #ffffff; /* Text color */
}

textarea {
  margin-top: 40px;
  min-height: 200px;
  resize: none;
  background-color: #2c2c2c; /* Dark background */
  color: #ffffff; /* Text color */
}

.form-control:focus {
  box-shadow: none;
  border-bottom-color: #1e90ff; /* Focus color */
}

::-moz-placeholder,
::-webkit-input-placeholder {
  font-size: 16px;
  color: #cccccc; /* Placeholder color */
}

/* LAYOUT
    ================================================== */
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #ffffff; /* Changed text color to white */
  background-color: #1e1e1e;
}

.img-res {
  display: block;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.visible-xxs {
  display: none;
}

/* Background-color */
.gray-bg {
  background-color: #1e1e1e;
}

/* HERO
      ================================================== */
.hero {
  height: 100%;
  width: 100%;
  min-height: 700px;
}

.hero .container {
  position: relative;
  height: 100%;
}

.hero .col-md-6:first-child {
  position: absolute;
  top: 35%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  z-index: 2;
}

.hero .col-md-6:last-child {
  position: absolute;
  right: 0;
  bottom: 0;
}

.hero h1 {
  font-size: 46px;
  font-weight: 700;
  margin: 0 0 15px;
  text-transform: uppercase;
  color: #ffffff; /* Changed to white */
}

.job-title {
  font-size: 22px;
  margin-bottom: 30px;
  line-height: 1;
  color: #cccccc; /* Lighter gray */
}

.hero img {
  height: 600px;
}
/* SITEMAIN
    ================================================== */
.site-main {
  position: relative;
  margin-bottom: 100px;
  background-color: #1e1e1e;
  z-index: 1;
}
/* site-section */
.site-section {
  padding-top: 30px;
}

.site-section h2,
.site-section h3 {
  font-size: 36px;
  margin: 0;
  text-transform: uppercase;
  color: #ffffff; /* Changed to white */
}

.img-lines {
  margin: 15px 0 40px;
}

.site-section p {
  margin-bottom: 35px;
  color: #cccccc; /* Lighter text */
}

/* Section: Portfolio */
.section-portfolio {
  padding-bottom: 120px;
  background-color: #1e1e1e; /* Dark background */
}

.portfolio-item {
  height: 280px;
  margin-bottom: 30px;
  position: relative;
}

.section-portfolio .col-md-4:nth-last-child(-n + 3) .portfolio-item {
  margin-bottom: 0;
}

.portfolio-item:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: background-color 0.25s ease-in-out;
  -moz-transition: background-color 0.25s ease-in-out;
  -webkit-transition: background-color 0.25s ease-in-out;
  -o-transition: background-color 0.25s ease-in-out;
  -ms-transition: background-color 0.25s ease-in-out;
  background-color: rgba(33, 33, 33, 0.8); /* Darker overlay */
}

.portfolio-item:hover:before {
  background-color: rgba(33, 33, 33, 0.9); /* Darker on hover */
}

.portfolio-item-info {
  position: absolute;
  top: 150px;
  left: 0px;
  z-index: 999;
  opacity: 0;
  transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
  -ms-transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
}

.portfolio-item:hover .portfolio-item-info {
  left: 50px;
  opacity: 1;
}

.portfolio-item-info h4 {
  margin: 0 0 20px;
  font-weight: 700;
  color: #ffffff; /* Changed to white */
}

.portfolio-item-info a {
  padding: 6px 7px 4px;
  font-size: 12px;
  border: 1px solid #ffffff; /* Changed border to white */
  border-radius: 50px;
  color: #ffffff; /* Changed text color to white */
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  -moz-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  -webkit-transition: color 0.25s ease-in-out,
    background-color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  -ms-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
}

.portfolio-item-info a:hover {
  color: #1e90ff; /* Highlight color */
  background-color: #ffffff; /* Light background */
}

.section-portfolio .btn {
  margin-top: 10px;
}

/* Modal */
.modal {
  z-index: 9999;
}

.modal-content {
  border-radius: 0;
  box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.5); /* Darker shadow */
  background-color: #2c2c2c; /* Darker background */
}

.modal-header {
  position: relative;
  padding: 0;
  height: 500px;
}

.modal-header .close {
  position: absolute;
  right: 0;
  padding: 10px 11px;
  margin-top: 0;
  background-color: #444444; /* Darker close button */
  font-size: 16px;
  color: #ffffff; /* Changed to white */
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  opacity: 1;
  transition: box-shadow 0.25s ease-in-out;
  -moz-transition: box-shadow 0.25s ease-in-out;
  -webkit-transition: box-shadow 0.25s ease-in-out;
  -o-transition: box-shadow 0.25s ease-in-out;
  -ms-transition: box-shadow 0.25s ease-in-out;
}

.modal-header .close:hover {
  box-shadow: 0px 10px 18px 0px rgba(255, 255, 255, 0.2); /* Lighter shadow on hover */
}

.modal-body h4 {
  font-weight: 700;
  color: #ffffff; /* Changed to white */
}

.modal-footer {
  text-align: center;
  color: #ffffff; /* Changed to white */
}

/* Section: Counters */
.section-counters {
  background: url(../assets/img/counters-bg.jpg) no-repeat fixed center / cover;
  width: 100%;
  padding: 100px 0;
  position: relative;
}

.section-counters:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.section-counters h4 {
  color: #ffffff; /* Changed to white */
  margin: 25px 0 0;
}

.site-section p.counter {
  font-size: 100px;
  font-weight: 300;
  color: #ffffff; /* Changed to white */
  margin: 0;
  line-height: 0.8;
}

/* Section: Form*/
.section-form {
  padding-bottom: 120px;
  background-color: #1e1e1e; /* Darker background */
}

.section-form .btn {
  margin-top: 40px;
  background-color: #333333; /* Darker button background */
  color: #ffffff; /* Button text color */
}

.section-form .btn:hover {
  background-color: #555555; /* Darken on hover */
}

/* FOOTER
    ================================================== */
.site-footer {
  position: fixed;
  bottom: 0;
  padding: 30px 0;
  width: 100%;
  background-color: #1e1e1e; /* Darker footer background */
  z-index: -1;
}

.icon {
  line-height: 40px;
  padding: 9px 11px;
  color: #ffffff; /* Changed to white */
  border: 1px solid #ffffff; /* Changed to white */
  border-radius: 50%;
}

.icon + .icon {
  margin-left: 5px;
}

.icon i:before {
  margin: 0;
}

.icon:hover {
  background-color: #ffffff;
  color: #1e1e1e; /* Changed text color on hover */
}

.site-footer a {
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  -moz-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  -webkit-transition: color 0.25s ease-in-out,
    background-color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  -ms-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  color: #ffffff; /* Changed to white */
}

a.twitter-bg:hover {
  color: #55acee;
}

a.facebook-bg:hover {
  color: #3b5998;
}

a.gplus-bg:hover {
  color: #007bb5;
}

a.linkedin-bg:hover {
  color: #dd4b39;
}

a.icon-up-bg:hover {
  color: #1e90ff; /* Changed to highlight color */
}

.copyright {
  margin-bottom: 0;
  line-height: 40px;
  color: #ffffff; /* Changed to white */
  text-align: center;
}

.custom-bg {
  height: 100%;
  width: 100%;
}

.react-parallax {
  height: 100%;
}

.react-parallax-content {
  height: 100%;
}
/* custom.css */

/* Shared Section Heading */
.section-heading {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: left;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #ffffff;
}

/* Shared Shadow Box */
.shadow-box {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
}

/* Button Shadows */
.btn-shadow {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
}

/* Text Box Shadows */
.text-box-shadow {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
}

/* custom.css */

/* ... other styles ... */

/* ... other styles ... */

/* Unified Button Style based on About Me section */
.btn-custom {
  display: inline-block;
  padding: 0.1rem 1.8rem;
  font-size: 0.8rem;
  background-color: transparent;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  border: 1px solid #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 2rem;
}

.btn-custom:hover {
  background-color: #ffffff;
  color: #1e1e1e;
}

/* Remove any conflicting styles */
.btn-custom-primary,
.btn-custom-secondary {
  display: none;
}

.section-header {
  font-family: "Cascadia Mono", monospace;
  font-weight: 700;
  font-size: 36px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 20px;
}
