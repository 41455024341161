.contact {
  padding: 80px 0;
  background-color: #1e1e1e;
  color: #ffffff;
  scale: 0.8;
}

.section-header {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.form-label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.form-control {
  font-size: 1rem;
  padding: 0.75rem;
  background-color: #2a2a2a;
  border: 1px solid #444;
  color: #a8a8a8;
}

.form-control:focus {
  background-color: #333;
  border-color: #666;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}

.btn-custom-secondary {
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  background-color: #36a2eb;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-custom-secondary:hover {
  background-color: #1e90ff;
}

.social-links-column {
  display: flex;
  justify-content: center;
  align-items: center;
  scale: 1.8;
}

.social-links-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.icon-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.linkedin-bg {
  background-color: #0077b5;
}

.github-bg {
  background-color: #333;
}

.email-bg {
  background-color: #d44638;
}

.icon-link:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .social-links-column {
    margin-top: 2rem;
  }

  .social-links-wrapper {
    justify-content: center;
  }
}
