.site-footer {
  background-color: #f8f9fa;
  padding: 20px 0;
  margin-top: 40px;
}

.icon-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
  transition: all 0.3s ease;
}

.icon-link:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.linkedin-bg {
  background-color: #0077b5;
}

.github-bg {
  background-color: #333;
}

.email-bg {
  background-color: #ea4335;
}

.icon-up-bg {
  background-color: #6c757d;
  border: none;
  cursor: pointer;
}

.copyright {
  font-size: 14px;
  color: #6c757d;
}

@media (max-width: 576px) {
  .site-footer .text-right {
    text-align: center !important;
  }
}
