/* HEADER

    ================================================== */

.site-header {

  position: fixed;

  top: 0;

  width: 100%;

  background-color: #ffffff; /* Changed back to white */

  z-index: 5;

  height: 80px;

  transition: all 0.3s ease;

}



.site-logo img {

  padding-bottom: 2px;

  height: 40px;

  transition: all 0.3s ease;

}



/* Navigation */

.navbar-nav > li > a {

  padding: 5px 0;

  font-weight: 600;

  color: #333333; /* Changed to dark color for contrast */

  text-transform: uppercase;

  border-bottom: 2px solid transparent;

  transition: border-color 0.25s ease-in-out;

  -moz-transition: border-color 0.25s ease-in-out;

  -webkit-transition: border-color 0.25s ease-in-out;

  -o-transition: border-color 0.25s ease-in-out;

  -ms-transition: border-color 0.25s ease-in-out;

}



.navbar-nav.navbar-right {

  margin-right: 0;

}



.navbar-nav > li + li > a {

  margin-left: 40px;

}



.navbar-nav > li > a:hover,

.navbar-nav > li.active > a,

.nav > li > a:hover,

.nav > li.active > a {

  background-color: transparent;

  border-bottom: 2px solid #333333; /* Changed to match text color */

}



.nav > li > a:focus {

  background-color: transparent;

}



.header-text {

  color: black;

  font-size: 0.7rem;

}



.shadow-box {

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);

}



.btn-shadow {

  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);

}



.text-box-shadow {

  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);

}



.nav-icon-item {

  font-size: 1.2rem;

  color: rgb(0, 51, 191);

  margin-bottom: 5px;

}



.navbar-scrolled {

  background-color: rgba(255, 255, 255, 0.9);

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}



.navbar-scrolled .site-logo img {

  height: 35px;

}



.navbar-nav .nav-link {

  color: #333333;

  font-weight: 600;

  text-transform: uppercase;

  padding: 0.5rem 1rem;

  transition: color 0.3s ease;

}



.navbar-nav .nav-link:hover,

.navbar-nav .nav-link:focus {

  color: #36a2eb;

}



.social-icons .nav-link {

  font-size: 1.2rem;

  padding: 0.5rem;

  color: #333333;

  transition: color 0.3s ease;

}



.social-icons .nav-link:hover {

  color: #36a2eb;

}



@media (max-width: 991px) {

  .navbar-nav {

    margin-top: 1rem;

  }



  .social-icons {

    margin-top: 1rem;

    justify-content: center;

  }

}



@media (max-width: 576px) {

  .site-logo img {

    height: 30px;

  }



  .navbar-scrolled .site-logo img {

    height: 25px;

  }

}



