/* About.css */

.about-section {
  background-color: #1e1e1e;
  color: #ffffff;
  width: 80%;
  padding: 15px 0;
  margin: 0 auto;
}

.about-container {
  margin: 0 auto;
}

.about-section h3 {
  font-size: 36px;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;

  margin-bottom: 20px;

  text-transform: uppercase;
  color: #ffffff;
}

.about-content {
  text-align: left;
  font-size: 1rem;
  line-height: 1.8;
}

.about-paragraph {
  margin-bottom: 1.5rem;
  padding-left: 20px;
  padding-right: 20px;
}

.about-highlight {
  color: #4bc0c0;
  font-weight: 600;
}

.cv-button {
  display: inline-block;
  padding: 0.5rem 1.8rem;
  font-size: 1rem;
  background-color: transparent;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  border: 1px solid #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 1rem;
  margin-left: 20px;
  margin-bottom: 20px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  scale: 0.8;
}

.cv-button:hover {
  background-color: #ffffff;
  color: #1e1e1e;
}

.shadow-box {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
}
