/* PortfolioItemModal.css */

.portfolio-modal .modal-content {
  width: 100%;
  max-height: 70vh;
  margin: 0 auto;
}

.portfolio-modal .modal-header {
  padding: 0.5rem 1rem;
}

.portfolio-modal .modal-body {
  padding: 1rem;
  max-height: 60vh;
  overflow-y: auto;
}

.portfolio-modal .modal-footer {
  padding: 0.5rem 1rem;
}

.portfolio-modal .project-details {
  font-size: 0.9rem;
  color: #666;
}

.portfolio-modal .project-body {
  font-size: 0.95rem;
}

.portfolio-modal .feature-list {
  padding-left: 1.2rem;
  font-size: 0.9rem;
}

.portfolio-modal .feature-list li {
  margin-bottom: 0.5rem;
}

.portfolio-modal img {
  max-height: 200px;
  object-fit: cover;
  width: 100%;
}

.portfolio-modal p {
  color: #cccccc;
}

.portfolio-modal ul {
  color: #cccccc;
}

.portfolio-modal .btn-primary {
  background-color: #36a2eb;
  border: none;
  transition: background-color 0.3s ease;
}

.portfolio-modal .btn-primary:hover {
  background-color: #1e90ff;
}
