.skills-scroll-window {
  padding-right: 15px;
  background-color: #1e1e1e;
  color: #e0e0e0;
  padding: 20px;
  border-radius: 10px;
}

.skills-scroll-window::-webkit-scrollbar {
  width: 8px;
}

.skills-scroll-window::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.skills-scroll-window::-webkit-scrollbar-thumb {
  background: #888;
}

.skills-scroll-window::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.skill-label {
  cursor: pointer;
  text-decoration: underline;
  color: #ff6384;
}

.skill-label:hover {
  color: #ff4567;
}

.shadow-box {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-shadow {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
}

.text-box-shadow {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
}
